.gridFullHeight{
    height: 100vh;
}

.loginImageBackground {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    background-color: gray(60); 
    transition: background-image 1s
}
  
.loginInteractionBoxContainer {
    display: flex;
    align-items: center; 
}
  
.loginInteractionBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 10% 5% 10%;
    max-width: 600px;
    max-height: 90vh;
    margin: auto;
    width: 100%; 
}
  
.loginList, .loginListContainer {
    width: 100%;
    margin-top: 2rem;
    overflow-y: auto;
    overflow-x: hidden; 
}
  
.loginListContainer {
    display: flex;
    flex-direction: row;
    white-space: nowrap; 
}
  
.footerButton {
    margin-top: 2rem !important; 
}
  
.imageCopyright {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(204, 204, 204, 0.4);
    color: #333;
    font-size: 8pt; 
}
  
.imageCopyright a {
    color: #444; 
}


.logo {
    background-image: url("./logo.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    padding-top: 120px;
    margin: 3rem
}